var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","spinner-variant":"primary","spinner-type":"grow","spinner-small":""}},[_c('b-card',{staticClass:"product-add-wrapper"},[_c('validation-observer',{ref:"addProductValidation"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('label',{attrs:{"for":"description-form"}},[_vm._v("Options for translate each program category")]),_c('transition-group',{attrs:{"id":"description-form","name":"custom-classes","enter-active-class":"animate__animated animate__fadeInDown","leave-active-class":"animate__animated animate__fadeOutUp","duration":300}},_vm._l((_vm.countProgramCategory),function(index){return _c('b-card-body',{key:index},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('div',{staticClass:"d-flex flex-row-reverse py-50 px-25 col-sm-12"},[(_vm.arrayProgramCategory.length > 1)?_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"size":"16","icon":"XIcon"},on:{"click":function($event){return _vm.removeItemDescription(index - 1)}}}):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.arrayProgramCategory[index - 1].name),callback:function ($$v) {_vm.$set(_vm.arrayProgramCategory[index - 1], "name", $$v)},expression:"arrayProgramCategory[index - 1].name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Language"}},[_c('validation-provider',{attrs:{"name":"Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"product-add-category","options":_vm.lanCodeOptions,"item-disabled":"disable","clearable":false,"selectable":function (option) {
                                return !_vm.arrayProgramCategory.find(function (x) { return x.languageCode.value === option.value; })
                              }},model:{value:(_vm.arrayProgramCategory[index - 1].languageCode),callback:function ($$v) {_vm.$set(_vm.arrayProgramCategory[index - 1], "languageCode", $$v)},expression:"arrayProgramCategory[index - 1].languageCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Description"}},[_c('b-form-input',{model:{value:(_vm.arrayProgramCategory[index - 1].description),callback:function ($$v) {_vm.$set(_vm.arrayProgramCategory[index - 1], "description", $$v)},expression:"arrayProgramCategory[index - 1].description"}})],1)],1)],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var ariaDescribedby = ref.ariaDescribedby;
return [_c('validation-provider',{attrs:{"name":"Default","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":index - 1},model:{value:(_vm.numberDefault),callback:function ($$v) {_vm.numberDefault=$$v},expression:"numberDefault"}},[_vm._v(" Default language ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]}}],null,true)})],1)])],1)}),1),_c('b-col',{staticClass:"pb-2",attrs:{"cols":"12"}},[(_vm.lanCodeOptions.length > _vm.arrayProgramCategory.length)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.addNewItemInItemFormDescription}},[_vm._v(" Add program category form in another language ")]):_vm._e()],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Featured Image ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.imgSrc,"height":"110","width":"170"}})],1),_c('b-media-body',[_c('div',[_c('small',{staticClass:"text-muted"},[_vm._v("Required image resolution 850x225, image size 5mb.")]),_c('b-card-text',{staticClass:"my-50"}),_c('div',{staticClass:"d-inline-block"},[_c('validation-provider',{attrs:{"name":"Featured image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif","placeholder":"Choose file"},on:{"change":_vm.onFileChange},model:{value:(_vm.imgFile),callback:function ($$v) {_vm.imgFile=$$v},expression:"imgFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])],1)],1)]),_c('b-col',{attrs:{"cols":"12"}},[(!_vm.$route.params.id)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.confirmAddProgramCate}},[_vm._v(" Add ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"to":{ name: 'program-category' },"variant":"outline-secondary"}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }